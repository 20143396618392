.social-logos {
	text-align: center;
}

.social-logos a {
	padding: 15px;
}

.footer {
	margin-bottom: 100px;
	margin-top: 50px;
}

.footer-links {
	margin-top: 50px;
	text-align: center;
}

.link:hover {
	text-decoration: white underline;
	cursor: pointer;
}

a {
	text-decoration: none;
}
