.mintBox {
	border: 1px solid white;
	margin: auto;
	padding-bottom: 40px;
	width: 100%;
	background: #ffffff;
	box-shadow: 5px 5px 11px #ccc;
	max-width: 600px;
}

#loadingText:before {
	content: attr(data-text);
	position: absolute;
	overflow: hidden;
	max-width: 8em;
	white-space: nowrap;
	color: var(--primary);
	animation: loading 20s linear;
}

@keyframes loading {
	0% {
		max-width: 0;
	}
}
