progress {
	text-align: center;
	height: 1.5em;
	width: 100%;
	-webkit-appearance: none;
	border: none;
	height: 40px;
	/* Set the progressbar to relative */
	position: relative;
}
progress:before {
	content: attr(data-label);
	font-size: 1rem;
	vertical-align: 1;
	color: white;
	/*Position text over the progress bar */
	position: absolute;
	top: 20px;
	right: 5px;
}
progress::-webkit-progress-bar {
	background-color: #c9c9c9;
}
progress::-webkit-progress-value {
	background-color: var(--primary);
}
progress::-moz-progress-bar {
	background-color: var(--primary);
}
